<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="searchItemBox" :class="{ 'pad-container': !isMembers }">
          <div class="formBox">
            <label class="label">订单编号:</label>
            <el-input
            placeholder="请输入订单编号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.orderNumber"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">流水编号:</label>
            <el-input
            placeholder="请输入订单流水号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.outTradeNo"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">手机号:</label>
            <el-input
            placeholder="请输入手机号"
            clearable
            class="search-input ml-15"
            v-model="searchObj.phone"
            v-if="!isMembers"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">订单状态:</label>
            <el-select
            clearable
            placeholder="请选择订单状态"
            class="search-input ml-15"
            v-model="searchObj.status"
            v-if="!isMembers"
            >
            <el-option
              v-for="(item, index) in order_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          </div>
          <div class="formBox">
            <label class="label">配送方式:</label>
            <el-select
            clearable
            placeholder="请选择配送方式"
            class="search-input ml-15"
            v-model="searchObj.deliveryType"
            v-if="!isMembers"
            >
             <el-option
              v-for="(item, index) in delivery_type"
              :key="index"
              :label="item.title"
              :value="item.id"
             ></el-option>
            </el-select>
          </div>
          <div class="formBox">
            <label class="label">拣货状态:</label>
            <el-select
            clearable
            placeholder="请选择拣货状态"
            class="search-input ml-15"
            v-model="searchObj.pickingStatus"
            v-if="!isMembers"
            >
             <el-option
              v-for="(item, index) in picking_status"
              :key="index"
              :label="item.title"
              :value="item.id"
             ></el-option>
            </el-select>
          </div>
          <div class="formBox">
            <label class="label">选择门店:</label>
            <el-select
            clearable
            placeholder="选择门店"
            class="search-input ml-15"
            v-model="searchObj.storeId"
            :disabled="disabled"
            v-if="!isMembers"
            >
             <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
             ></el-option>
            </el-select>
          </div>
          <!-- <div class="formBox">
            <label class="label">最早送达时间:</label>
            <el-input
            placeholder="请输入订单编号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.orderNumber"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">最晚送达时间:</label>
            <el-input
            placeholder="请输入订单编号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.orderNumber"
            ></el-input>
          </div> -->
          <div class="formBox1">
            <label class="label">下单时间:</label>
            <el-date-picker
            v-model="searchObj.createTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择下单时间"
            :default-time="['00:00:00','23:59:59']"
            />
          </div>
          <div class="formBox1">
            <label class="label">核销时间:</label>
            <el-date-picker
            v-model="searchObj.writeOffTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            type="daterange"
            range-separator="至"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择核销时间"
            />
          </div>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
<!--          <el-button icon="el-icon-upload2" type="primary" @click="exportExcel"-->
<!--            >导出</el-button-->
<!--          >-->
          <el-button type="primary" icon="el-icon-refresh" @click="exportFile()">导出</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格+分页 -->
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div :class="{ 'pad-container': !isMembers }">
          <!-- 表格 -->
          <el-table class="member-table" :data="tableData"
            ref="tableDataArea">
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <!-- <el-button @click="goDetail(scope.row)" type="text">
                    详情
                  </el-button> -->
                  <!-- <el-button @click="createDadaOrder(scope.row.id)"
                    type="text" v-if="scope.row.status == 8 || scope.row.status == 22">
                    呼叫骑手
                  </el-button> -->
                  <!-- <el-popover
                     placement="top"
                     width="360"
                     :model="callingDaDa"
                     v-if="scope.row.status == 8 || scope.row.status == 22">
                     <p>请选择呼叫骑手时间</p>
                     <div style="text-align: right; margin: 0;" class="callingDaDaButton">
                       <el-button type="primary" size="mini" @click="calling(scope.row.id)">当前立即呼叫</el-button>
                       <el-button type="primary" size="mini" @click="autoCall(scope.row.id)">预约时间系统自动呼叫</el-button>
                     </div>
                     <el-button slot="reference" type="text" style="padding-left:12px">呼叫骑手</el-button>
                  </el-popover> -->
                  <!-- <el-button @click="changePickingStatus(scope.row, 1)"
                    type="text"
                    v-if="scope.row.pickingStatus == 0 || scope.row.pickingStatus == 2">
                    确认拣货
                  </el-button> -->
                  <!-- <el-button @click="changePickingStatus(scope.row, 2)"
                    type="text"
                    v-if="scope.row.pickingStatus == 0">
                    暂时挂起
                  </el-button> -->
                  <el-tooltip effect="dark" content="确认拣货" placement="top">
                    <i class="iconfont icon-jianhuo1 ml-10 iconfontSelf" @click="changePickingStatus(scope.row, 1)"  v-if="scope.row.pickingStatus == 0 || scope.row.pickingStatus == 2 || scope.row.pickingStatus == null"></i>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="暂时挂起" placement="top">
                    <i class="iconfont icon-guaqi1 ml-10 iconfontSelf" @click="changePickingStatus(scope.row, 2)" v-if="scope.row.pickingStatus == 0"></i>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="打印小票" placement="top">
                    <i class="el-icon-printer ml-10 iconfontSelf" @click="printTicketHandle(scope.row.id)" v-if="scope.row.status != 0 && scope.row.status != 6"></i>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="退款" placement="top">
                    <i class="iconfont icon-tuikuan ml-10 iconfontSelf"  @click="refund(scope.row)"   v-if="(scope.row.status > 0 &&(scope.row.status < 4 || scope.row.status > 7)) ||scope.row.status == -1"></i>
                  </el-tooltip>
                  <!-- <el-button @click="refund(scope.row)"
                    type="text"
                    class="ml-10"
                    v-if="(scope.row.status > 0 &&(scope.row.status < 4 || scope.row.status > 7)) ||scope.row.status == -1"
                      >退款
                  </el-button> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column  label="小票号">
              <template slot-scope="scope">
                <span v-if="scope.row.printNumber">#{{ scope.row.printNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNumber" width="200">
              <template slot-scope="scope">
                <el-tooltip effect="dark" content="点击即可进入详情页面" placement="top">
                <span @click="goDetail(scope.row)" style="cursor:pointer;color:#409EFF">{{scope.row.orderNumber}}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="预约配送时间" prop="appointmentTime" width="200"></el-table-column>
            <el-table-column label="配送方式" >
              <template slot-scope="scope">
                <span>{{ getDeliveryTypeStr(scope.row.deliveryType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" >
              <template slot-scope="scope">
                <span>{{ getString(1, scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="拣货状态" >
              <template slot-scope="scope">
                <span>{{ getPickingStatusStr(scope.row.pickingStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="达达配送状态"  width="120">
              <template slot-scope="scope">
                <span>{{ getDadaDeliveryStatusStr(scope.row.dadaDeliveryStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="telephone" width="110"></el-table-column>
            <el-table-column label="所属门店" prop="storeName" width="110"></el-table-column>
            <el-table-column label="实付金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPay || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="满减金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.reductionAmount || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="惠购券">
              <template slot-scope="scope">
                <span>￥{{ scope.row.hgoCouponReduce || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="打包费">
              <template slot-scope="scope">
                <span>￥{{ scope.row.packingFee || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="配送费">
              <template slot-scope="scope">
                <span>￥{{ scope.row.deliveryFee || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="下单时间" prop="createTime" show-overflow-tooltip width="180"></el-table-column>
            <el-table-column label="核销时间" prop="writeoffTime"  show-overflow-tooltip width="180"></el-table-column>
            <el-table-column label="核销员" prop="writeoffName"></el-table-column>
            <el-table-column label="支付流水号" prop="outTradeNo" width="200"></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 拒绝弹窗 start -->
    <el-dialog
      :visible.sync="refuseVisible"
      width="450px"
      title="确认提示"
      @close="cancel"
    >
      <div class="flx-row mb-10">
        <div>
          <i class="el-icon-question question"></i>
        </div>
        <div>
          <div class="title1">是否确定拒绝接单</div>
          <div class="title2">拒绝接单将原路退回金额</div>
        </div>
      </div>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="reason">
          <el-select v-model="form.reason" class="form-input-400">
            <el-option
              v-for="(item, index) in reasonOptions"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm('form')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 拒绝弹窗 end -->

    <!-- 退款弹窗 start -->
    <el-dialog
      :visible.sync="refundVisible"
      width="450px"
      :title="refundTitle"
      @close="cancelRefund"
    >
      <div class="flx-row mb-10">
        <div>
          <i class="el-icon-question question"></i>
        </div>
        <div>
          <div class="title1">是否确定退款</div>
          <div class="title2">退款将原路退回金额</div>
        </div>
      </div>
      <el-form
        :model="formRefund"
        :rules="refundRules"
        ref="formRefund"
        label-position="left"
        label-width="100px"
      >
        <el-form-item prop="cancelReasonId" label="选择理由">
          <el-select v-model="formRefund.cancelReasonId" placeholder="请选择理由">
            <el-option
              v-for="item in cancelReasonList"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="refundReason" label="理由">
          <el-input
            v-model="formRefund.refundReason"
            placeholder="请输入理由"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="goodsReturnStatus" label="商品退还状态">
          <el-input
                  v-model="formRefund.goodsReturnStatus"
                  placeholder="请输入商品退还状态，商品是否已退回门店、是否影响二次销售等"
                  type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="refundOptions" label="审核意见">
          <el-input
                  v-model="formRefund.refundOptions"
                  placeholder="请输入审核意见"
                  type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="liablePerson" label="防损责任人">
          <el-input
            v-model="formRefund.liablePerson"
            placeholder="请输入防损责任人"
          ></el-input>
        </el-form-item>
        <el-form-item prop="refundOperator" label="退款操作人">
          <el-input
            v-model="formRefund.refundOperator"
            placeholder="请输入退款操作人 "
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button @click="cancelRefund">取消</el-button>
        <el-button type="primary" @click="confirmRefund('formRefund')"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 退款弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {downFile2} from "@/utils/index.js";
import {
  getOrderDetail,
  orderRefundRefuse,
  orderRefundAccept,
  printTicket,
  getRefundOrderList,
  updatePickingStatus,
  createDadaOrder,
} from "@/api/transactions/order/orders_department";
import '@/assets/icons/iconfont/iconfont.js';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      reasonOptions: [
        {
          label: "缺货",
          value: "缺货",
        },
      ],
      form: { reason: "" },
      rules: {
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "理由不能为空",
          },
        ],
      },
      formRefund: {
        refundReason: "",
        goodsReturnStatus: "",
        refundOptions: "",
        liablePerson: "",
        refundOperator: "",
        cancelReasonId: null,
      },
      refundRules: {
        refundReason: [
          {
            required: true,
            trigger: "change",
            message: "理由不能为空",
          },
        ],
        goodsReturnStatus: [
          {
            required: true,
            trigger: "change",
            message: "商品退还状态不能为空",
          },
        ],
        refundOptions: [
          {
            required: true,
            trigger: "change",
            message: "审核意见不能为空",
          },
        ],
        liablePerson: [
          {
            required: true,
            trigger: "change",
            message: "防损责任人不能为空",
          },
        ],
        refundOperator: [
          {
            required: true,
            trigger: "change",
            message: "退款操作人不能为空",
          },
        ],
        cancelReasonId: [
          {
            required: true,
            trigger: "change",
            message: "理由选项不能为空"
          }
        ]
      },
      refundVisible: false,
      refundTitle: "确认退款",
      refundRow: {},
      qrText: "",
      captureObj: {},
      img: "",
      dialogVisible: false,
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: null,
        createTime: null,
        deliveryType: null,
        pickingStatus: null,
        writeOffTime: null,
        outTradeNo:null,
        // estimatedDeliveryStartTime:null,//预约配送开始时间
        // estimatedDeliveryEndTime:null,//预约配送结束时间
      },
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "待自提",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "退款中",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: 7,
          title: "已拒单",
        },
        {
          id: 8,
          title: "待配送",
        },
        {
          id: 9,
          title: "配送中",
        },
        {
          id: 10,
          title: "取货中",
        },
         {
          id: 12,
          title: "待调度",
        },
        {
          id: -1,
          title: "退款失败",
        },
        {
          id: 20,
          title: "已超自提时间"
        },
        {
          id: 21,
          title: "妥投异常物品返回中"
        },
        {
          id: 22,
          title: "妥投异常物品已归还"
        }
      ],
      order_type: [
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 1,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
      ],
      delivery_type:[
        {
          id: 0,
          title: "配送到家"
        },
        {
          id: 1,
          title: "到店自提"
        }
      ],
      picking_status: [
        {
          id: 0,
          title: "待拣货"
        },
        {
          id: 1,
          title: "已拣货"
        },
        {
          id: 2,
          title: "已挂起"
        }
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      totalNum: 0,
      url: "/order-service/orderOSM/backstageExpert",
      refuseVisible: false,
      disabled: false,
      row: {},
      dadaDeliveryStatusList: [
        {status: 1, name: "待接单"},
        {status: 2, name: "待取货"},
        {status: 100, name: "已到店"},
        {status: 3, name: "配送中"},
        {status: 4, name: "已完成"},
        {status: 5, name: "订单取消"},
        {status: 9, name: "妥投异常物品返回中"},
        {status: 10, name: "妥投异常物品已归还"},
      ],
      cancelReasonList: [
          {reason: "没有配送员接单",id: 1},
          {reason: "配送员没来取货", id: 2},
          {reason: "配送员态度太差", id: 3},
          {reason: "顾客取消订单", id: 4},
          {reason: "订单填写错误", id: 5},
          {reason: "配送员让我取消此单", id: 34},
          {reason: "配送员不愿上门取货", id: 35},
          {reason: "我不需要配送了", id: 36},
          {reason: "配送员以各种理由表示无法完成订单", id: 37},
          {reason: "其他", id: 10000},
          {reason: "被其他平台抢单", id: 20199}
      ],
      callMode:1,
      callingDaDa:false,
      mouseOffset:0,
      mouseFlag:false
    };
  },
  props: {
    isMembers: {
      type: Boolean,
      default: false,
    },
    // 从会员中心 个人详情那边传值过来  memberId
    memberId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getStores();
    this.initData();
  },
  methods: {
        //初始化状态
    initData() {
      if (this.$store.getters.storeId) {//门店管理员&& this.$store.getters.userObject.userRoleVos[0].type==3
        this.searchObj.storeId = this.$store.getters.storeId;
        this.disabled = true;
      }
      let data = {
        outTradeNo:this.searchObj.outTradeNo,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderNumber: this.searchObj.orderNumber,
        telephone: this.searchObj.phone,
        storeId: this.searchObj.storeId,
        // orderType: this.searchObj.orderType,
        startTime: this.searchObj.createTime
          ? this.searchObj.createTime[0]
          : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[0] : "",
        writeOffEndTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[1] : "",
        status:
          this.searchObj.status || this.searchObj.status == 0
            ? this.searchObj.status
            : "",
        deliveryType: this.searchObj.deliveryType,
        pickingStatus: this.searchObj.pickingStatus,
        // estimatedDeliveryStartTime: this.searchObj.estimatedDeliveryStartTime,
        // estimatedDeliveryEndTime: this.searchObj.estimatedDeliveryEndTime
      };
      if (this.memberId) {
        data.createCustormer = this.memberId;
      }
      getRefundOrderList(data).then((res) => {
        let tempTime = res.data.body.body.list;
        for(let i= 0;i<tempTime.length;i++){
          if(tempTime[i].estimatedDeliveryStartTime){
            let tempStartTime = tempTime[i].estimatedDeliveryStartTime.slice(0,16)
            let tempEndTime = tempTime[i].estimatedDeliveryEndTime.slice(11,16)
            tempTime[i].appointmentTime = tempStartTime+"-"+tempEndTime
          }else{
            tempTime[i].appointmentTime = ''
          }
        }
        this.tableData = tempTime
        this.total = res.data.body.body.total;
      });
    },
    // 打印小票
    printTicketHandle(id) {
      printTicket(id).then(() => {
        this.$message.success("打印成功");
      });
    },
    // 表格导出
    exportExcel() {
      let data = {
        outTradeNo:this.searchObj.outTradeNo,
        orderNumber: this.searchObj.orderNumber,
        telephone: this.searchObj.phone,
        storeId: this.searchObj.storeId,
        // orderType: this.searchObj.orderType,
        startTime: this.searchObj.createTime
                ? this.searchObj.createTime[0]
                : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[0] : "",
        writeOffEndTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[1] : "",
        status:
                this.searchObj.status || this.searchObj.status == 0
                        ? this.searchObj.status
                        : "",
        deliveryType: this.searchObj.deliveryType,
        pickingStatus: this.searchObj.pickingStatus,
        // estimatedDeliveryStartTime: this.searchObj.estimatedDeliveryStartTime,
        // estimatedDeliveryEndTime: this.searchObj.estimatedDeliveryEndTime
      };
      // console.log(data);
      this.downFile(this.url, "商超到家订单", data);
    },
    changeStatus(row, status) {
      let msg = "";
      if (status == 8) {
        msg = "确定收货吗";
      }
      if (status == 9) {
        msg = "确定呼叫骑手吗";
      }
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doChangeStatus(row, status);
      });
    },
    // 改变状态
    doChangeStatus(row, status) {
      let data = {
        id: row.id,
        orderNumber: row.orderNumber,
        status,
        rejectionReason: status == 7 ? this.form.reason : undefined,
      };
      orderRefundRefuse(data).then(() => {
        if (status == 7) {
          let data = {
            id: row.id,
          };
          orderRefundAccept(data).then(() => {
            this.$message({
              type: "success",
              message: "拒单成功",
            });
            this.cancelRefund();
            this.initData();
          });
        } else {
          let msg = "";
          switch (status) {
            case 8:
              msg = "确认接单成功";
              break;
            case 9:
              msg = "呼叫骑手成功";
              break;
          }
          this.$message.success(msg);
          this.initData();
        }
      });
    },
    confirm(formName) {
      let form = this.$refs[formName];
      this.submitValid(this, form, this.doSubmit);
    },
    // 拒绝接单：同时触发退款逻辑；状态改为“退款中”
    doSubmit() {
      this.doChangeStatus(this.row, 7);
    },
    cancel() {
      this.$refs.form.resetFields();
      this.refuseVisible = false;
    },
    // 拒绝接单
    refuse(row) {
      this.refuseVisible = true;
      this.row = row;
    },
    cancelRefund() {
      //关闭退款弹窗
      this.$refs.formRefund.resetFields();
      this.refundVisible = false;
    },
    // 打开退款弹窗
    refund(row) {
      this.refundVisible = true;
      this.refundRow = row;
    },
    confirmRefund(formName) {
      //确认退款验证及提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.formRefund);
          // console.log(this.refundRow);
          this.refundVisible = false;
          let data = {
            ...this.formRefund,
            id: this.refundRow.id,
          };
          // console.log(data);
          orderRefundAccept(data).then(() => {
            this.$message({
              type: "success",
              message: "退款成功",
            });
            this.initData();
            this.refundVisible = false;
          });
        } else {
          return false;
        }
      });
    },

    goDetail(row) {
      //去订单详情页面
      this.$router.push({
        name: "Orders_home_details",
        query: {
          id: row.id,
          // member: row.customer.body && row.customer.body.cdmtype
        },
      });
    },

    getDetail(row) {
      //获取订单详情
      getOrderDetail(row.id).then((res) => {
        this.captureObj = res.data.body;
        this.captureObj.storeName = row.storeName;
        this.captureObj.phone = row.phone;
        this.captureObj.nickname = row.nickname;
        this.qrText = `{ "code": ${this.captureObj.qrCode},"type":0,"title":"订单" }`;
        this.totalNum = 0;
        this.captureObj.list.map((item) => {
          this.totalNum += item.goodsNum;
        });
        this.dialogVisible = true;
      });
    },

    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: null,
        createTime: null,
        pickingStatus: null,
        deliveryType: null,
        outTradeNo:null,
        // estimatedDeliveryStartTime: null,
        // estimatedDeliveryEndTime: null
      };
      this.initData();
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    search() {
      //筛选到家订单列表
      this.initData();
    },
    syncPageData(data) {
      //异步更新页面大小和页数
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    /*** 获取配送方式*/
    getDeliveryTypeStr(deliveryType) {
      let deliveryTypeStr = ''
      this.delivery_type.forEach((item) => {
        if(deliveryType == item.id) {
          deliveryTypeStr = item.title
          return
        }
      })
      return deliveryTypeStr
    },
    /*** 获取拣货状态*/
    getPickingStatusStr(status) {
      let pickingStatusStr = ''
      this.picking_status.forEach(item => {
        if(status == item.id) {
          pickingStatusStr = item.title
          return
        }
      })
      return pickingStatusStr
    },
    /**
     * 修改拣货状态
     * @param id 订单id
     * @param status 拣货状态
     */
    changePickingStatus(row, status){
      let data = {
        id: row.id,
        pickingStatus: status
      }
      updatePickingStatus(data).then(res => {
        if(res.data.code == 200) {
          let text = ''
          if(status == 1){
            text = '拣货成功'
          } else if(status == 2) {
            text = '挂起成功'
          }
          row.pickingStatus = status
          this.$message.success(text);
        }
      }).catch((err)=>{
        console.log(err)
      })
    },
    // 呼叫骑手
    createDadaOrder(id) {
      let data = {
        id: id,
        callMode:this.callMode
      }
      createDadaOrder(data).then(res => {
        if(res.data.code === 200) {
          this.$message.success('呼叫骑手成功');
          this.initData();
        }
      })
    },
    getDadaDeliveryStatusStr(id) {
      let text = ''
      this.dadaDeliveryStatusList.forEach(item => {
        if(item.status === id) {
          text = item.name
          return
        }
      })
      return text
    },
    // 新版带预约时间呼叫达达配送
    calling(e){//当前立即发起呼叫
      this.callingDaDa = false
      this.callMode = 1,
      this.createDadaOrder(e);
    },
    autoCall(e){//到达预约时间系统自动发起呼叫
      this.callingDaDa = false
      this.callMode = 2,
      this.createDadaOrder(e);
    },
    //导出功能
    exportFile() {
      let params = {
        outTradeNo:this.searchObj.outTradeNo,
        orderNumber: this.searchObj.orderNumber,
        telephone: this.searchObj.phone,
        storeId: this.searchObj.storeId,
        // orderType: this.searchObj.orderType,
        startTime: this.searchObj.createTime
                ? this.searchObj.createTime[0]
                : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[0] : "",
        writeOffEndTime: this.searchObj.writeOffTime ? this.searchObj.writeOffTime[1] : "",
        status:
                this.searchObj.status || this.searchObj.status == 0
                        ? this.searchObj.status
                        : "",
        deliveryType: this.searchObj.deliveryType,
        pickingStatus: this.searchObj.pickingStatus,
        // estimatedDeliveryStartTime: this.searchObj.estimatedDeliveryStartTime,
        // estimatedDeliveryEndTime: this.searchObj.estimatedDeliveryEndTime
      };
      downFile2(process.env.VUE_APP_BASE_API + '/order-service/orderOSM/exportGoodsOrderOSM', '商超到家订单', params, 'post')
              .then((data) => {
                console.log(data)
                if (!data) {
                  this.$message.warning("文件下载失败")
                  return
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                  window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                  let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
                  let link = document.createElement('a')
                  link.style.display = 'none'
                  link.href = url
                  link.setAttribute('download', 'excel导出文件.xls')
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                }
              })
    },
  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
.question {
  font-size: 30px;
  color: orange;
  margin-right: 5px;
}
.title1 {
  font-size: 18px;
  color: #333;
  font-size: weight;
  margin-bottom: 5px;
}
.title2 {
  font-size: 14px;
  color: #999;
}
.searchItemBox{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .label{
    width: 70px;
    font-size: 16px;
    font-weight: normal;
    display: block;
    line-height: 40px;
    margin-left: 16px;
    min-width: 70px;
  }
  .formBox{
    width: 25%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
  }
  .formBox1{
    width: 37.5%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
    .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: auto;
  }
  }
}
.callingDaDaButton{
  display: flex;
  justify-content: space-between;
}
// ----iconfont----彩色----
.icon{
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.iconfontSelf{
  font-size: 24px;
};
</style>
